import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

import './EditProfile.css';
import Navbar from '../../components/Navbar/Navbar';
import Bottom from '../../components/Bottom/Bottom';
import FooterWithLinks from '../../components/FooterWithLinks/FooterWithLinks';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

import defaultUserImage from '../../assets/user.png';
// import sharp from 'sharp';

//my changes

const EditProfile = () => {
  const [user_details, setUserDetails] = useState(
    () => JSON.parse(localStorage.getItem('userDetails')) || {}
  );
  const [fullName, setFullName] = useState(user_details?.fullName);
  const [email, setEmail] = useState(user_details?.email);
  const [address, setAddress] = useState(user_details?.address);
  const [phone, setPhone] = useState(user_details?.phone); // Corrected typo here
  const [timeZone, setTimeZone] = useState('IST');
  const [currentTime, setCurrentTime] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [userData, setUserData] = useState(null);

  const [preview, setPreview] = useState(null);
  

  const fileInputRef = useRef(null);
  const [addressDisabled, setAddressDisabled] = useState(true);
  const [phoneDisabled, setPhoneDisabled] = useState(true);

  const addressRef = useRef(null);
  const phoneRef = useRef(null);

  // Function to enable the input and focus on it when edit icon is clicked
  const handleEditClick = (field) => {
    if (field === 'address') {
      setAddressDisabled(false); // Enable the address input
      setTimeout(() => addressRef.current.focus(), 0);
    }
    if (field === 'phone') {
      setPhoneDisabled(false); // Enable the phone input
      setTimeout(() => phoneRef.current.focus(), 0);
    }
  };
  

  const apiUrl = process.env.REACT_APP_SERVER_URL;

  const getUserData = async () => {
    try {
      const userId = localStorage.getItem('userId'); // Retrieve the userId from localStorage
  
      if (!userId) {
        throw new Error('User ID not found in local storage.');
      }
  
      const response = await axios.get(`${apiUrl}/api/v1/user/id/${userId}`, {
        withCredentials: true,
      });
  
      setUserData(response.data.data);
      localStorage.setItem('userId', response.data.data.id);
      localStorage.setItem('userDetails', JSON.stringify(response.data.data));
      setPreview(response?.data?.data?.avatar);
      console.log(response?.data?.data);
      setUserDetails(response.data.data); // Also update the state directly
    } catch (error) {
      console.error('Error Fetching User Data.', error);
      window.location.href = '/login';
    }
  };
  
  // Add this useEffect to update state when userData is available
  useEffect(() => {
    if (userData) {
      setFullName(userData.fullName || '');
      setEmail(userData.email || '');
      setAddress(userData.address || '');
      setPhone(userData.phone || '');
    }

  }, [userData]);

  useEffect(() => {
    getUserData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userId = localStorage.getItem('userId');

    const requestOptions = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        id: userId,
        niyam: 0,
        fullName: fullName,
        address: address,
        phone: phone,
      }),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/user/update`,
        requestOptions
      );
      const data = await response.json();
      console.log(data);
      setAddress(data.data.address)
      if (response.status === 200) {
        const updatedUserDetails = {
          ...user_details,
          fullName,
          address,
          phone,
        };

        // Save updated details to localStorage to reflect changes instantly
        localStorage.setItem('userDetails', JSON.stringify(updatedUserDetails));
        setUserDetails(updatedUserDetails); // Update state to trigger re-render
    
        toast.success('User details updated successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
        setAddressDisabled(true);
        setPhoneDisabled(true);
      }
    } catch (error) {
      console.error('Error updating user details:', error);
    }
  };

  const handlePasswordSubmit = async (event) => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      setErrorMessage('New password and confirm password do not match');
      return;
    }

    const userId = localStorage.getItem('userId');

    const requestOptions = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        id: userId,
        email: email,
        oldPassword: oldPassword,
        newPassword: newPassword,
      }),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/user/change-password`,
        requestOptions
      );
      const data = await response.json();
      // console.log(data);
    } catch (error) {
      console.error('Error changing password:', error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files?.[0] || null;
    const maxSize = 300 * 1024;
    const allowedTypes = [
      'image/jpeg',
      'image/png',
      'image/jpg',
      'image/heic',
      'image/heif',
    ]; // 300KB in bytes

    if (file && !allowedTypes.includes(file.type)) {
      toast.error('Image should be in jpg/jpeg or png format');
      event.target.value = ''; // Reset the input field
      return;
    }
    if (file && file.size > maxSize) {
      toast.error('Image size should be less than 300KB.');
      event.target.value = ''; // Reset the input field if file is too large
    } else {
      if (file) {
        setPreview(URL.createObjectURL(file));
        
      }
      handleImageSubmit(file);
      setSelectedFile(file); // Save file only if within size limit
    }
  };

  const handleImageSubmit = async (file) => {
    const userId = localStorage.getItem('userId');

    const formData = new FormData();
    formData.append('id', userId);
    formData.append('avatar', file);

    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/user/upload-profile`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        }
      );
          console.log(response);
     
      } catch (error) {
      console.error('Error uploading profile picture:', error);
    }
    if (file) {
      console.log('File is ready to be uploaded:', file);
    }
  };
  console.log(preview);
  return (
    <div className="edit-main">
      <ToastContainer />
      <div className="edit my-3">
        <div className="edit-user-details">
          <div className="edit-image">
            <img
              className="profile-pic"
              src={preview || defaultUserImage}
              alt="Profile"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = defaultUserImage;
              }}
            />

            {/* Center: Name and Email */}
            <div className="details">
              <p style={{ textTransform: 'capitalize' }} className="user-name">
                {fullName || 'Full Name'}
              </p>
              <p className="user-email">{email || 'Email Address'}</p>
              <form
                action="/upload"
                method="post"
                enctype="multipart/form-data"
              >
                <input
                  name="file"
                  type="file"
                  ref={fileInputRef}
                  accept="image/*"
                  onChange={handleFileChange}
                  className="file-input"
                />
                <button
                  className="Upload-New-Picture"
                  type="button"
                  onClick={() => fileInputRef.current?.click()}
                >
                  <FontAwesomeIcon
                    icon={faUpload}
                    className="edit-icon-upload"
                  />
                  Upload Picture
                </button>
                <p className="size">Max size: 300KB</p>
              </form>
            </div>
          </div>
          
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="fullName">Full Name:</label> <br />
              <input
                style={{ textTransform: 'capitalize' }}
                type="text"
                id="fullName"
                value={fullName}
                required
                disabled
              />
            </div>
            <div>
              <label htmlFor="email">Email:</label> <br />
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                disabled
              />
            </div>

            <div className="input-container">
              <label htmlFor="phone">Phone:</label> <br />
              <div className="input-with-icon">
                <input
                  type="tel"
                  id="phone"
                  value={phone}
                  onChange={(e) => {
                    const regex = /^[0-9\b]+$/;
                    if (
                      e.target.value === '' ||
                      (regex.test(e.target.value) &&
                        e.target.value.length <= 10)
                    ) {
                      setPhone(e.target.value);
                    }
                  }}
                  required
                  disabled={phoneDisabled}
                  ref={phoneRef} 
                />
                <FontAwesomeIcon
                  icon={faEdit}
                  className="edit-icon"
                  onClick={() => handleEditClick('phone')}
                />
              </div>
            </div>
            <div className="input-container">
              <label htmlFor="address">Address:</label> <br />
              <div className="input-with-icon">
                <input
                  type="text"
                  id="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                  disabled={addressDisabled}
                  ref={addressRef}
                />
                <FontAwesomeIcon
                  icon={faEdit}
                  className="edit-icon"
                  onClick={() => handleEditClick('address')}
                />
              </div>
            </div>
           
            <button type="submit">Save</button>
          </form>
        </div>
        {/* <div className="edit-change-password">
          <h2>Change Password</h2>
          <form onSubmit={handlePasswordSubmit}>
            <div>
              <label>Old Password:</label> <br />
              <input
                type="password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                required
              />
            </div>
            <div>
              <label>New Password:</label> <br />
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Confirm Password:</label> <br />
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            <button type="submit">Change Password</button>
          </form>
        </div> */}
      </div>
      <FooterWithLinks />
    </div>
  );
};

export default EditProfile;
