import React, { useEffect, useState } from 'react';
import 'remixicon/fonts/remixicon.css';
import confetti from 'canvas-confetti';

const Popup2 = ({ wordCount }) => {
  const [displaypop, setdisplaypop] = useState(false);
  const [task, settask] = useState(false);
  const [milestone, setmilestone] = useState(null);
  const [content, setcontent] = useState({ message: ' ', content: '' });

  const user = JSON.parse(localStorage.getItem('userDetails'));

  useEffect(() => {
    getUserData();
  }, [user]);

  // Fetch user data and trigger milestone checks
  const getUserData = () => {
    if (!user) {
      console.error('No user logged in.');
      return;
    }

    const dateregistration = new Date(user.createdAt);
    const mantraCount = user.mantraChanted + wordCount;

    taskcomplete(dateregistration, mantraCount);
  };

  // Different messages for different milestones
  useEffect(() => {
    if (!user) {
      console.log('No user found');
      return;
    }

    // Calculate mantraCount based on user.mantraChanted and wordcount prop
    const mantraCount = (user?.mantraChanted || 0) + (wordCount || 0);

    if (mantraCount >= 1150000) {
      setcontent({
        message:
          'Congratulations, you have successfully written 11,50,000 mantra in your first year.',
        content:
          'As a reward, we are offering to become Yajman absolutely free for Kalsarp Yog and Grah Shanti Yagya organized annually by the trust on the occasion of Nagpanchami. Use below contacts to know further.',
      });
    } else if (mantraCount >= 161000) {
      setcontent({
        message:
          'Congratulations, you have successfully written 1,61,000 mantra in your first month.',
        content:
          'As a reward, we are offering you a Sampurna Jeevan Darpan for free. Use below contacts to know further.',
      });
    } else if (mantraCount >= 101000) {
      setcontent({
        message:
          'Congratulations, you have successfully written 1,01,000 mantra in your first month.',
        content:
          'As a reward, we are offering you a free Jyotish consultation. Use below contacts to know further.',
      });
    } else {
      setcontent({ message: '', content: '' });
    }
  }, [user?.mantraChanted, wordCount]);

  // Check whether the milestone is completed and set the milestone
  const taskcomplete = (dateregistration, mantraCount) => {
    const present = new Date();
    const daysleft = Math.floor(
      (present - dateregistration) / (1000 * 60 * 60 * 24)
    );

    let currentMilestone = null;

    if (daysleft <= 30 && mantraCount >= 101000) {
      currentMilestone = 'milestone1';
      if (daysleft <= 30 && mantraCount >= 161000) {
        currentMilestone = 'milestone2';
        if (daysleft <= 365 && mantraCount >= 1150000) {
          currentMilestone = 'milestone3';
        }
      }
    }

    if (currentMilestone) {
      if (milestone !== currentMilestone) {
        setmilestone(currentMilestone);
        settask(true);
      }
    }
  };

  // Confetti burst
  const triggerConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
      colors: ['#de4620', '#ffd700', '#ffffff'],
      zIndex: 9999,
    });

    setTimeout(() => {
      confetti({
        particleCount: 50,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors: ['#de4620', '#ffd700'],
        zIndex: 9999,
      });
    }, 300);

    setTimeout(() => {
      confetti({
        particleCount: 50,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: ['#de4620', '#ffd700'],
        zIndex: 9999,
      });
    }, 600);
  };

  // Function to ensure the popup is displayed only once for each milestone
  const Popup = () => {
    if (!user || !milestone) return;

    const userId = user.id;
    const localpopdata =
      JSON.parse(localStorage.getItem('mantraPagePopup')) || {};
   
    if (!localpopdata[userId]) {
      localpopdata[userId] = {};
    }  
    if (localpopdata[userId][milestone]) {
      return;
    }
    setdisplaypop(true);
    triggerConfetti();

    localpopdata[userId][milestone] = true;
    localStorage.setItem('mantraPagePopup', JSON.stringify(localpopdata));
  };

  useEffect(() => {
    if (task) {
      Popup();
    }
  }, [task, milestone]);

  const closePopup = () => {
    setdisplaypop(false);
  };

  return (
    displaypop && (
      <>
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-40">
          {/* popup */}
          <div className="w-full max-w-xl text-center  ">
            {/* header */}
            <div className="w-full bg-[#de4620] p-4 text-white font-black rounded-t-2xl relative">
              <button
                onClick={closePopup}
                className="absolute top-2 right-4 font-bold z-50 "
              >
                <i class="ri-close-large-fill"></i>
              </button>
              <h2>🎉 Achievement Unlocked!</h2>
            </div>
            {/* content */}
            <div className="bg-white w-full p-3 rounded-b-2xl">
              {/* message */}
              <div className="text-lg md:text-2xl font-bold">
                <p>{content.message}</p>
                <p className="text-[#de4620]">{content.content}</p>
              </div>

              {/* contact */}
              <div className="overflow-hidden py-3 my-3 mx-3 bg-gray-200 rounded-xl">
                <h5 className="text-md md:text-2xl font-bold">Contacts:</h5>
                <h6 className="text-lg md:text-3xl font-bold text-[#de4620]">
                  092220 41001
                </h6>
                <h6 className="text-lg md:text-3xl  font-bold text-[#de4620]">
                  095990 96366
                </h6>
                <h6 className="text-lg md:text-2xl">
                  Email:{' '}
                  <span className="text-[#de4620] break-words">
                    contact@masavitrijyotish.com
                  </span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default Popup2;
