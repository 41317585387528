import React, { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const Card = ({ mantraCount, target,  }) => {
  const [diffDays, setDiffDays] = useState(null);
  const [isTaskCompleted, setIsTaskCompleted] = useState(false);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [content, setContent] = useState({
    heading: ' ',
    content: '',
     });

  const user = JSON.parse(localStorage.getItem('userDetails')) || {};
  const remainingMantra = Math.max(
    mantraCount - (user.mantraChanted || 0),
    0
  );

  useEffect(() => {
    if (mantraCount === 101000) {
      setContent({
        heading: 'Free Jyotish Consultation',
        content: '1,01,000',
       
      });
    } else if (mantraCount === 161000) {
      setContent({
        heading: 'Sampurna Jeevan Darpan',
        content: '1,61,000',
       
      });
    } else if (mantraCount === 1150000) {
      setContent({
        heading: 'Become a Yajman for Free',
        content: '11,50,000',
              });
    } else {
      setContent({ heading: '', content: ''});
    }
  }, [mantraCount]);

  useEffect(() => {
    if (!user.createdAt) return;

    const registrationDate = new Date(user.createdAt);
    const targetEndDate = new Date(
      registrationDate.setDate(registrationDate.getDate() + target)
    );
    const currentDate = new Date();
    const diffTime = targetEndDate - currentDate; // Difference in milliseconds
    const remainingDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    setDiffDays(remainingDays > 0 ? remainingDays : 0);

    // Calculate completion percentage
    const chantedMantras = user.mantraChanted || 0;
    const percentage = Math.min(
      (chantedMantras / mantraCount) * 100,
      100
    );
    setCompletionPercentage(percentage);

    // check if task is completed
    if (remainingMantra === 0) {
      setIsTaskCompleted(true);
    } else {
      setIsTaskCompleted(false);
    }
     
  }, [user, mantraCount, target, remainingMantra]);
  

  return (
    <div
  className="w-full md:w-80 lg:w-[30%] p-4 rounded-l-2xl rounded-br-2xl shadow-2xl flex items-center bg-white"
>
  <div className="w-[20%] mr-6">
    <CircularProgressbar
      value={diffDays > 0 ? completionPercentage : 0 }
      text={
        isTaskCompleted
          ? "✓"
          : diffDays > 0  
          ? `${Math.round(completionPercentage)}%`
          : "❌"
      }
      styles={buildStyles({
        textColor: completionPercentage < 100 ? "#000" : "#008000",
        pathColor: completionPercentage < 100 ? "#3182CE" : "#008000",
        trailColor: "#d6d6d6",
      })}
    />
    <p className="text-xs text-center mt-2">{content.content} mantras</p>
  </div>
  <div>
    <h2 className="text-lg font-bold text-gray-800 mb-2">{content.heading}</h2>
    {isTaskCompleted ? (
      <>
        <span className="text-sm text-green-600">Congratulations!</span>
        <br />
        <span className="text-sm text-green-600">Milestone Completed.</span>
      </>
    ) : diffDays > 0 ? (
      <>
        <span className="text-sm text-gray-600">
          Remaining Mantra: {remainingMantra}
        </span>
        <br />
        <span className="text-sm text-gray-600">Days Left: {diffDays}</span>
      </>
    ) : (
      <>
        <span className="text-sm text-red-600">No Time Left!</span>
        <br />
        <span className="text-sm text-red-600">Milestone Unachievable.</span>
      </>
    )}
  </div>
</div>

  );
};

export default Card;
