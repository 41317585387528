import React, { useState, useEffect } from 'react';
import './TopUsers.css';
import { Link } from 'react-router-dom';
import FooterWithLinks from '../../components/FooterWithLinks/FooterWithLinks';
import defaultUserImage from '../../assets/user.png';

const TopUsersPage = () => {
  const [topUsersWeek, setTopUsersWeek] = useState([]);
  const [topUsersToday, setTopUsersToday] = useState([]);
  const [topUsersMonth, setTopUsersMonth] = useState([]);
  const [topUsersAllTime, setTopUsersAllTime] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const weekResponse = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/mantralekhan/week`
      );
      const allTimeResponse = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/mantralekhan/alltime`
      );
      const monthResponse = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/mantralekhan/month`
      );
      const todayResponse = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/mantralekhan/today`
      );

      if (!weekResponse.ok || !allTimeResponse.ok) {
        throw new Error('Failed to fetch data');
      }
      if (!monthResponse.ok) {
        throw new Error('Failed to fetch data');
      }

      const weekjsonData = await weekResponse.json();
      const allTimejsonData = await allTimeResponse.json();
      const monthjsonData = await monthResponse.json();
      const todayJsonData = await todayResponse.json();

      setTopUsersWeek(weekjsonData.data);
      setTopUsersAllTime(allTimejsonData.data);
      setTopUsersMonth(monthjsonData.data);
      setTopUsersToday(todayJsonData.data);
    } catch (error) {
      throw new Error('Catch');
    }
  };

  const [activeTab, setActiveTab] = useState('today');

  const topUsersData = {
    today: topUsersToday,
    week: topUsersWeek,
    month: topUsersMonth,
    allTime: topUsersAllTime,
  };

  function Separator({ color = 'black', height = 1 }) {
    return (
      <hr
        style={{
          backgroundColor: color,
          height: height,
          border: 'none',
        }}
      />
    );
  }

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  };

  return (
    <div className="topusers-main">
      <div className="top-header">
        <h1>Top Users</h1>
      </div>
      <div className="top-users-page">
        <div className="top-users-container">
          <div className="top-users-content">
            <div className="tabs">
              <button
                className={activeTab === 'today' ? 'active' : ''}
                onClick={() => handleTabClick('today')}
              >
                Today
              </button>
              <button
                className={activeTab === 'week' ? 'active' : ''}
                onClick={() => handleTabClick('week')}
              >
                This Week
              </button>
              <button
                className={activeTab === 'month' ? 'active' : ''}
                onClick={() => handleTabClick('month')}
              >
                This Month
              </button>
              <button
                className={activeTab === 'allTime' ? 'active' : ''}
                onClick={() => handleTabClick('allTime')}
              >
                Top 25
              </button>
            </div>
            <div className="top-users-list">
              <Separator color="yellow" height="1px" />

              {topUsersData[activeTab].length > 0 ? (
                topUsersData[activeTab].slice(0, 25).map((user, index) => (
                  <div key={index} className="user-card">
                    <div className="user-info">
                      <div className="serial-number">{index + 1}.</div>{' '}
                      {/* Serial number */}
                      <div>
                        <img
                          src={user.avatar || defaultUserImage}
                          alt="User"
                          className="user-image"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = defaultUserImage;
                          }}
                        />
                      </div>
                      <div>
                        <h3 className="user-name">{user.fullName}</h3>
                        <p style={{ margin: '0px 0' }}>
                          Total Mantralekhan: {user.totalCount}
                        </p>
                        <p style={{ margin: '0px 0' }}>
                          Member Since: {formatDate(user.memberSince)}
                        </p>
                      </div>
                    </div>
                    <Separator color="yellow" height="1px" />
                  </div>
                ))
              ) : (
                <p
                  style={{
                    textAlign: 'center',
                    margin: '20px 0',
                    color: 'gray',
                  }}
                >
                  No users found
                  {activeTab === 'today'
                    ? ' for today.'
                    : activeTab === 'week'
                      ? ' for this week.'
                      : activeTab === 'month'
                        ? ' for this month.'
                        : ' in the top 25.'}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="middle-stump">
          <Link to={'/mantrapage'}>
            <button className="button">Start Mantralekhan</button>
          </Link>
        </div>
      </div>

      <FooterWithLinks />
    </div>
  );
};

export default TopUsersPage;
