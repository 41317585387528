import React, { useState, useEffect } from 'react';
import Card from '../../components/Card';
import BarChart from './BarChart';
import axios from 'axios';
import FooterWithLinks from '../../components/FooterWithLinks/FooterWithLinks';
import defaultUserImage from '../../assets/user.png';

const MyPerformance = () => {
  const user_details = JSON.parse(localStorage.getItem('userDetails'));
  const userId = localStorage.getItem('userId');

  const [userMantraData, setUserMantraData] = useState(null);
  const [currentMonthGraphData, setCurrentMonthGraphData] = useState(null);
  const [previousMonthGraphData, setPreviousMonthGraphData] = useState(null);
  const [performanceData, setPerformanceData] = useState({
    fullName: 'Fetching...',
    todayMantralekhan: 'Fetching...',
    weekMantralekhan: 'Fetching...',
    totalMantralekhan: 'Fetching...',
  });
  const [currentDateTime, setCurrentDateTime] = useState(null);
  const [userData, setUserData] = useState(null);
  const apiUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    const getUserData = async () => {
      try {
        axios.defaults.withCredentials = true;
        let response = await axios(`${apiUrl}/api/v1/login/success`, {
          method: 'GET',
          withCredentials: true,
        });

        setUserData(response.data.data);
        localStorage.setItem('userId', response.data.data.id);
        localStorage.setItem('userDetails', JSON.stringify(response.data.data));
        fetchPerformanceData();
        fetchData();
      } catch (error) {
        console.log('Error Fetching User Data.', error);
        window.location.href = '/login';
      }
    };

    const fetchPerformanceData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/user/performance/${userId}`,
          {
            credentials: 'include',
          }
        );
        const performanceDataFetched = await response.json();
        // console.log(performanceDataFetched);
        setPerformanceData(performanceDataFetched.data);
      } catch (error) {
        throw new Error('Failed To Fetch User Data');
      }
    };

    const fetchData = async () => {
      try {
        const userMantraDataResponse = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/user/stats/${userId}`,
          {
            credentials: 'include',
          }
        );

        if (!userMantraDataResponse.ok) {
          throw new Error('Failed To Fetch Mantralekhan Stats Of User.');
        }

        const userMantraStats = await userMantraDataResponse.json();
        // console.log(userMantraStats);
        setUserMantraData(userMantraStats);
      } catch (error) {
        throw new Error('Catch');
      }
    };
    getUserData();
  }, []);

  useEffect(() => {
    // console.log(userMantraData);

    if (userMantraData) {
      setCurrentMonthGraphData({
        labels: userMantraData.data.currentMonthMantralekhanDto?.map(
          (data) => data.date
        ),
        datasets: [
          {
            label: 'This Month',
            data: userMantraData?.data.currentMonthMantralekhanDto?.map(
              (data) => data.mantraCount
            ),
            backgroundColor: ['#f3ba2f'],
            borderColor: 'white',
            borderWidth: 2,
          },
        ],
      });

      setPreviousMonthGraphData({
        labels: userMantraData.data.previousMonthMantralekhanDto?.map(
          (data) => data.date
        ),
        datasets: [
          {
            label: 'Previous Month',
            data: userMantraData?.data.previousMonthMantralekhanDto?.map(
              (data) => data.mantraCount
            ),
            backgroundColor: ['#f3ba2f'],
            borderColor: 'white',
            borderWidth: 2,
          },
        ],
      });
    }
  }, [userMantraData]);

  return (
    <>
      <div className="w-full h-full flex flex-col bg-gradient-to-b from-white to-[#FFF7D4] items-center justify-center mt-3   ">
        <h1 className="text-2xl font-bold  text-[#800000] text-center uppercase">
          Spiritual Journey
        </h1>

        {/* user info card */}
        <div className="w-full flex justify-center">
          <div className="w-[85%] flex mt-4  justify-center">
            <div className="bg-white shadow-md rounded-lg p-6 w-full">
              {/* Profile Section */}
              <div className="flex items-center justify-center mb-6">
                <div className="flex-shrink-0">
                  <img
                    src={user_details.avatar || defaultUserImage} // Use default image if user_details.avatar is null/undefined
                    alt="Profile"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = defaultUserImage; // Set default image if error occurs
                    }}
                    className="h-12 w-12 rounded-full object-cover"
                  />
                </div>
                <div className="ml-4">
                  <h1 className="text-[#de4620] text-2xl font-semibold">
                    {performanceData.fullName}
                  </h1>
                </div>
              </div>

              {/* Stats Section */}
              <div className="flex flex-col sm:flex-row sm:justify-between gap-4 text-center">
                <div className="flex-1 bg-[#FFF7D4] p-4 rounded-lg">
                  <p className="text-gray-600 text-base">
                    Today's Mantralekhan
                  </p>
                  <p className="text-[#de4620] text-xl font-bold">
                    {performanceData.todayMantralekhan}
                  </p>
                </div>
                <div className="flex-1 bg-[#FFF7D4] p-4 rounded-lg">
                  <p className="text-gray-600 text-base">This Week</p>
                  <p className="text-[#de4620] text-xl font-bold">
                    {performanceData.weekMantralekhan}
                  </p>
                </div>
                <div className="flex-1 bg-[#FFF7D4] p-4 rounded-lg">
                  <p className="text-gray-600 text-base">Overall</p>
                  <p className="text-[#de4620] text-xl font-bold">
                    {' '}
                    {performanceData.totalMantralekhan}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* cards */}

        <div className="w-full flex justify-center ">
          <div className="w-[85%] flex flex-col mt-5 justify-center">
            <h2 className="text-2xl text-center text-[#800000]">
              Milestones
            </h2>
            <div className="flex mt-5   flex-wrap justify-center lg:justify-between gap-2 rounded-lg">
              <Card target={31} mantraCount={101000} />
              <Card target={31} mantraCount={161000} />
              <Card target={365} mantraCount={1150000} />
            </div>
          </div>
        </div>
        {/* graph  */}
        <div className="w-full flex justify-center mb-5">
          <div className="w-[85%] flex flex-col mt-5 justify-center items-center ">
            <h2 className="text-2xl text-center text-[#800000]">
              Performance Over Time
            </h2>
            <div className="w-full flex  flex-col md:flex-row gap-2 mt-4  ">
              <div className="w-full flex justify-center  md:w-[50%]">
                {' '}
                {currentMonthGraphData && (
                  <BarChart chartData={currentMonthGraphData} />
                )}
              </div>
              <div className="w-full flex justify-center  md:w-[50%]">
                {' '}
                {previousMonthGraphData && (
                  <BarChart chartData={previousMonthGraphData} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterWithLinks />
    </>
  );
};

export default MyPerformance;

